import React from 'react'
import intl from 'react-intl-universal'
import { CardWrapper } from './atoms'
import { Flex, Button, Link } from '@raysync/common/components'
import DownloadIcon from '@raysync/common/components/svg/docs-download.jsx'
import { CardContainer, DownloadBox, CardTitle } from './atoms'
import card1 from './images/card_1.png'
import card2 from './images/card_2.png'
import card3 from './images/card_3.png'
import { range } from '@raysync/common/utils'

const imgArray = [card1, card2, card3]

class DocumentCard extends React.PureComponent {
  render() {
    const { type, size } = this.props
    return (
      <React.Fragment>
        <CardWrapper>
          {range(3).map(i => (
            <CardContainer key={i} size={size}>
              <img src={imgArray[i]} alt='document card' />
              <CardTitle>{intl.get(`${type}.document${i + 1}.title`)}</CardTitle>
              <DownloadBox
                download
                nofollow
                noindex
                target='_blank'
                href={`https://raysync.oss-cn-shenzhen.aliyuncs.com/setup/document/${intl.get(
                  `${type}.document${i + 1}.filename`,
                )}`}
              >
                <DownloadIcon />
                {intl.get('app.document3.download')}
              </DownloadBox>
            </CardContainer>
          ))}
        </CardWrapper>
        <Flex>
          <Link to='/support/document/'>
            <Button variant='outlined' size='large'>
              {intl.get('app.document.download.more')}
            </Button>
          </Link>
        </Flex>
      </React.Fragment>
    )
  }
}

export default DocumentCard
