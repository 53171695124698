import React from 'react'

const SvgFeature5En = props => (
  <svg id='feature5_en_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 620 280' xmlSpace='preserve' {...props}>
    <style>
      {
        '.feature5_en_svg__st0{fill:#8796aa}.feature5_en_svg__st1{fill:#00b5b0}.feature5_en_svg__st2{fill:#fd5c1f}.feature5_en_svg__st4{font-size:12px}.feature5_en_svg__st5{font-family:&apos;MyriadPro-Regular&apos;}.feature5_en_svg__st6{enable-background:new}.feature5_en_svg__st7{fill:#252a3a}'
      }
    </style>
    <path className='feature5_en_svg__st0' d='M296.92 139.9l146-.85-.01-2-146 .85' />
    <path className='feature5_en_svg__st0' d='M442.36 144.06l7.97-6.04-8.03-5.96' />
    <path
      className='feature5_en_svg__st1'
      d='M255.11 132.08c0 3.93 3.23 7.11 7.22 7.11s7.22-3.18 7.22-7.11-3.23-7.11-7.22-7.11-7.22 3.18-7.22 7.11zM266.1 141.35h-6.03c-5.1 0-9.26 4.01-9.33 9.01v.58c0 2.03 4.18 2.03 9.33 2.03h6.03c5.17 0 9.33 0 9.33-2.03v-.58c-.07-5-4.23-9.01-9.33-9.01z'
    />
    <path
      className='feature5_en_svg__st2'
      d='M462.59 259.87v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.01-12.67-2.94z'
    />
    <path
      className='feature5_en_svg__st2'
      d='M462.59 251.15v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.01-12.67-2.94zM462.59 268.32v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.02-12.67-2.94z'
    />
    <path
      className='feature5_en_svg__st2'
      d='M475.26 251.61c7.25 0 12.67-2.12 12.67-4.01s-5.42-4.01-12.67-4.01-12.67 2.12-12.67 4.01 5.42 4.01 12.67 4.01z'
    />
    <text
      transform='translate(243.943 183.576)'
      className='feature5_en_svg__st0 feature5_en_svg__st4'
      fontFamily='SourceHanSansCN-Normal-GBpc-EUC-H'
    >
      {'Admin'}
    </text>
    <text
      transform='translate(499.943 22.576)'
      className='feature5_en_svg__st0 feature5_en_svg__st5 feature5_en_svg__st4'
    >
      {'Transfer Log'}
    </text>
    <text transform='translate(499.943 75.576)' className='feature5_en_svg__st6'>
      <tspan x={0} y={0} className='feature5_en_svg__st0 feature5_en_svg__st5 feature5_en_svg__st4'>
        {'End users, Users'}
      </tspan>
      <tspan x={0} y={14.4} className='feature5_en_svg__st0 feature5_en_svg__st5 feature5_en_svg__st4'>
        {'Groups Management'}
      </tspan>
    </text>
    <text transform='translate(499.943 136.576)' className='feature5_en_svg__st6'>
      <tspan x={0} y={0} className='feature5_en_svg__st0 feature5_en_svg__st5 feature5_en_svg__st4'>
        {'Network,Transfer'}
      </tspan>
      <tspan x={0} y={14.4} className='feature5_en_svg__st0 feature5_en_svg__st5 feature5_en_svg__st4'>
        {'System Management'}
      </tspan>
    </text>
    <text
      transform='translate(499.943 199.576)'
      className='feature5_en_svg__st0 feature5_en_svg__st5 feature5_en_svg__st4'
    >
      {'Access Management'}
    </text>
    <text transform='translate(499.943 257.576)' className='feature5_en_svg__st6'>
      <tspan x={0} y={0} className='feature5_en_svg__st0 feature5_en_svg__st5 feature5_en_svg__st4'>
        {'Data Storage'}
      </tspan>
      <tspan x={0} y={14.4} className='feature5_en_svg__st0 feature5_en_svg__st5 feature5_en_svg__st4'>
        {'Management'}
      </tspan>
    </text>
    <path className='feature5_en_svg__st0' d='M368.62 19.7l74-.5-.01-2-73.99.5' />
    <path className='feature5_en_svg__st0' d='M442.07 24.21l7.97-6.04-8.03-5.96M368.62 258.7l74-.5-.01-2-73.99.5' />
    <path className='feature5_en_svg__st0' d='M442.07 263.2l7.97-6.04-8.03-5.96M368.62 198.95l74-.5-.01-2-73.99.5' />
    <path className='feature5_en_svg__st0' d='M442.07 203.45l7.97-6.03-8.03-5.97M368.62 79.45l74-.5-.01-2-73.99.5' />
    <path className='feature5_en_svg__st0' d='M442.07 83.95l7.97-6.03-8.03-5.96M370.91 258.06v-240h-2v240' />
    <g>
      <path className='feature5_en_svg__st0' d='M53.91 139.91h47v-2h-47' />
      <path className='feature5_en_svg__st0' d='M100.33 144.91l8-6-8-6' />
    </g>
    <g>
      <path className='feature5_en_svg__st0' d='M176.91 140.26h47v-2h-47' />
      <path className='feature5_en_svg__st0' d='M223.33 145.26l8-6-8-6' />
    </g>
    <g>
      <path
        className='feature5_en_svg__st1'
        d='M40.34 118.91H9.61c-2.5 0-4.51 1.97-4.51 4.33s2.01 4.33 4.51 4.33h30.74c2.46 0 4.51-1.93 4.51-4.33-.01-2.4-2.06-4.33-4.52-4.33zm-18.03 5.33h-12v-2.67h12v2.67zm6.22.35c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48 1.54.66 1.54 1.48c0 .78-.68 1.48-1.54 1.48zm5.49 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48.04.78-.64 1.48-1.54 1.48zm5.31 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48.04.78-.68 1.48-1.54 1.48zM40.34 128.91H9.61c-2.46 0-4.51 1.93-4.51 4.33 0 2.36 2.01 4.33 4.51 4.33h30.74c2.46 0 4.51-1.93 4.51-4.33-.01-2.4-2.06-4.33-4.52-4.33zm-18.03 6h-12v-2.67h12v2.67zm6.31.3c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48 1.54.66 1.54 1.48c.04.82-.69 1.48-1.54 1.48zm5.52 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48 1.54.66 1.54 1.48-.68 1.48-1.54 1.48zm5.32 0c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48c.86 0 1.54.66 1.54 1.48s-.69 1.48-1.54 1.48zM40.51 147.58c2.46 0 4.51-1.93 4.51-4.33 0-2.36-2.01-4.33-4.51-4.33H9.78c-2.46 0-4.51 1.93-4.51 4.33 0 2.36 2.01 4.33 4.51 4.33h14.53v4h-2v2.67h-16v2.67h16v2h6.67v-2h14.67v-2.67H28.97v-2.67h-2v-4h13.54zm-.97-6.16c.86 0 1.54.66 1.54 1.48s-.69 1.48-1.54 1.48c-.86 0-1.54-.66-1.54-1.48s.69-1.48 1.54-1.48zm-5.31 0c.86 0 1.54.66 1.54 1.48s-.69 1.48-1.54 1.48c-.86 0-1.54-.66-1.54-1.48s.68-1.48 1.54-1.48zm-5.49 0c.86 0 1.54.66 1.54 1.48s-.69 1.48-1.54 1.48-1.54-.66-1.54-1.48c-.04-.82.65-1.48 1.54-1.48zm-6.43 2.82h-12v-2.67h12v2.67z'
      />
    </g>
    <g>
      <path
        className='feature5_en_svg__st2'
        d='M145.25 126.5c-2.91 0-5.81.56-8.37 1.19l1.89 1.36c-2.55.48-5.16.34-6.59.02v5.57c1.94.51 8.33.14 11.13-1.01l-.88-1.06c1.03-.1 1.73-.12 2.81-.12 2.98 0 5.81.41 8.37 1.16v-5.92c-2.41-.6-5.45-1.19-8.36-1.19z'
      />
      <path
        className='feature5_en_svg__st7'
        d='M154.13 148.11c-1.95.2-3.32.52-4.7.92v1.64c-2.16.49-5.65.97-8.46.79-2.9-.18-6.65-1.23-8.27-1.85v-5.89c2.29 1.22 6.63 2.04 10.21 2.04 1.99 0 4.44-.26 5.88-.56l-.28-1.47c1.83-.63 2.97-1.16 5.64-1.42v5.8h-.02zM150.47 134.73c-2.03 0-5 .47-5.84.7l.97 1.56c-2.19.55-4.37.54-5.97.54-3.76 0-6.95-.79-10.27-2.06v5.9c2.55.87 5.82 1.54 8.93 1.54s6.51-.42 9.42-1.43l-.27-.72c1.01-.13 1.88-.19 2.99-.19 2.09 0 3.84.07 6.02.59v-5.92c-1.21-.25-3.94-.51-5.98-.51z'
      />
      <g>
        <path
          className='feature5_en_svg__st0'
          d='M144.81 116c11.09.96 19.95 9.82 20.91 20.91h2.01c-.97-12.19-10.72-21.94-22.91-22.91l-.01 2zM119.91 136.91c.96-11.09 9.82-19.95 20.91-20.91v-2c-12.19.97-21.94 10.72-22.91 22.91h2zM140.81 161.82c-11.09-.96-19.95-9.82-20.91-20.91h-2.01c.97 12.19 10.72 21.94 22.91 22.91v-2h.01zM165.72 140.91c-.96 11.09-9.82 19.95-20.91 20.91v2.01c12.19-.97 21.94-10.72 22.91-22.91l-2-.01z'
        />
      </g>
    </g>
    <g>
      <path
        className='feature5_en_svg__st1'
        d='M263.09 163.97c-13.79 0-25-11.21-25-25s11.21-25 25-25 25 11.21 25 25-11.22 25-25 25zm0-48c-12.68 0-23 10.32-23 23s10.32 23 23 23 23-10.32 23-23-10.32-23-23-23z'
      />
    </g>
    <g>
      <path
        className='feature5_en_svg__st2'
        d='M488.45 67.44l-11-6.18c-1.25-.7-3.13-.7-4.38 0l-11 6.18c-1.26.71-2.21 2.32-2.21 3.76v13.07c0 1.43.95 3.05 2.21 3.76l11.01 6.18c.61.34 1.39.53 2.18.53.8 0 1.57-.19 2.18-.53l11.01-6.19c1.26-.71 2.21-2.32 2.21-3.76V71.19c0-1.43-.95-3.05-2.21-3.75zm.21 16.82c0 .72-.56 1.66-1.19 2.01l-11.01 6.18c-.62.35-1.78.35-2.41 0l-11.01-6.18c-.63-.36-1.19-1.3-1.19-2.01V71.19c0-.72.56-1.66 1.19-2.01l11-6.18c.32-.18.76-.27 1.21-.27.45 0 .89.09 1.21.27l11 6.18c.63.36 1.19 1.3 1.19 2.01v13.07h.01z'
      />
      <path
        className='feature5_en_svg__st2'
        d='M475.31 71.64c.08.05.18.07.28.07s.2-.03.28-.07l2.09-1.22c.19-.11.31-.31.31-.53v-2.43c0-.22-.12-.42-.31-.53l-2.07-1.21c-.16-.1-.43-.1-.6 0l-2.08 1.22c-.19.11-.3.31-.3.53v2.43c0 .22.12.42.31.53l2.09 1.21zM474.92 73.65v5c.13.3.37.55.67.55.3 0 .54-.25.67-.55v-5c-.13-.3-.37-.55-.67-.55-.29 0-.53.25-.67.55zM484.16 78.61c.09 0 .18-.02.27-.07a.55.55 0 00.2-.75c-.11-.2-1.65-3.21-6.1-5.73a.533.533 0 00-.73.21c-.14.27-.05.6.21.74 4.18 2.36 5.66 5.26 5.69 5.31.1.19.28.29.46.29zM472.65 72.06c-4.45 2.51-5.99 5.53-6.1 5.73-.15.26-.06.6.2.75a.535.535 0 00.73-.21c.02-.05 1.51-2.95 5.69-5.31.26-.15.35-.48.21-.74a.534.534 0 00-.73-.22zM482.96 80.33h4.02v4.02h-4.02zM473.58 80.33h4.02v4.02h-4.02zM464.21 80.33h4.02v4.02h-4.02z'
      />
    </g>
    <path
      className='feature5_en_svg__st2'
      d='M475.26 119.61c-9.37 0-17 7.63-17 17s7.63 17 17 17 17-7.63 17-17-7.63-17-17-17zm0 32c-2.09 0-4.27-2.56-5.54-6.81a33.565 33.565 0 0011.08 0c-1.28 4.25-3.45 6.81-5.54 6.81zm0-8.35c-2.22 0-4.26-.21-6.07-.58-.37-1.81-.58-3.85-.58-6.07s.21-4.26.58-6.07c1.81-.37 3.85-.58 6.07-.58s4.26.21 6.07.58c.37 1.81.58 3.85.58 6.07s-.21 4.26-.58 6.07c-1.81.37-3.85.58-6.07.58zm-8.19-1.11c-4.25-1.27-6.81-3.45-6.81-5.54s2.56-4.27 6.81-5.54c-.3 1.73-.46 3.59-.46 5.54s.16 3.81.46 5.54zm8.19-20.54c2.09 0 4.27 2.56 5.54 6.81a33.565 33.565 0 00-11.08 0c1.27-4.25 3.45-6.81 5.54-6.81zm8.19 9.46c4.25 1.27 6.81 3.45 6.81 5.54s-2.56 4.27-6.81 5.54c.3-1.73.46-3.59.46-5.54s-.17-3.81-.46-5.54zm6 .72c-1.61-1.24-3.83-2.24-6.46-2.91-.67-2.63-1.67-4.85-2.91-6.46 4.39 1.5 7.87 4.97 9.37 9.37zm-19.02-9.37c-1.24 1.61-2.24 3.83-2.91 6.46-2.63.67-4.85 1.67-6.46 2.91 1.5-4.4 4.98-7.87 9.37-9.37zm-9.36 19.01c1.61 1.24 3.83 2.24 6.46 2.91.67 2.63 1.67 4.85 2.91 6.46a15.04 15.04 0 01-9.37-9.37zm19.01 9.37c1.24-1.61 2.24-3.83 2.91-6.46 2.63-.67 4.85-1.67 6.46-2.91-1.5 4.39-4.98 7.87-9.37 9.37z'
    />
    <g>
      <path
        className='feature5_en_svg__st2'
        d='M466.69 11.16h9.9c.3 0 .49-.2.49-.5s-.2-.5-.4-.5h-9.9c-.3 0-.49.2-.49.5s.21.5.4.5z'
      />
      <path
        className='feature5_en_svg__st2'
        d='M480 3.16h-15.5c-1.95 0-3.25 1.69-3.25 3.76V33.4c0 2.07 1.3 3.76 3.25 3.76h20.95c1.95 0 3.8-1.69 3.8-3.76V12.36L480 3.16zm.26 2.69l6.95 7.32h-5.09c-1.21 0-1.86-1.03-1.86-2.35V5.85zm7 27.4c0 1.05-1.08 1.91-2.06 1.91h-20.62c-.88 0-1.32-.84-1.32-1.86V7.03c0-1.03.53-1.86 1.32-1.86h13.68v6.65c0 1.82 1.18 3.35 2.94 3.35h6.06v18.08z'
      />
      <path
        className='feature5_en_svg__st2'
        d='M466.08 16.66c0 .3.2.5.49.5h16.99c.3 0 .49-.2.49-.5s-.3-.5-.49-.5h-16.99c-.29 0-.49.2-.49.5zM474.93 28.75c1.6-1.96 1.51-4.85-.32-6.68-1.95-1.95-5.12-1.95-7.07 0s-1.95 5.12 0 7.07c1.83 1.83 4.72 1.92 6.68.32l1.8 1.8c.2.2.51.2.71 0s.2-.51 0-.71l-1.8-1.8zm-1.02-.32c-1.56 1.56-4.1 1.56-5.66 0s-1.56-4.1 0-5.66 4.1-1.56 5.66 0 1.56 4.1 0 5.66z'
      />
    </g>
    <g>
      <path
        className='feature5_en_svg__st2'
        d='M476.52 184.95l-1.11 1.11 3.32 3.31-3.32 3.32 1.11 1.1 4.42-4.42zM468.44 184.95l-4.43 4.42 4.43 4.42 1.1-1.1-3.31-3.32 3.31-3.31z'
      />
      <path
        transform='rotate(-69.439 472.473 189.432)'
        className='feature5_en_svg__st2'
        d='M467.78 188.65h9.38v1.56h-9.38z'
      />
      <path className='feature5_en_svg__st2' d='M460.26 177.08v34h11.95v-1.7h-10.23v-30.6h26.56v17h1.72v-18.7z' />
      <path
        className='feature5_en_svg__st2'
        d='M489.26 203.91c.05-1.6-.52-3.13-1.62-4.3a6 6 0 00-4.18-1.9c-1.59-.05-3.13.52-4.3 1.62-2.41 2.26-2.54 6.07-.28 8.48a5.99 5.99 0 004.38 1.9c1.15 0 2.3-.34 3.3-1l2.23 2.38 1.46-1.37-2.23-2.38c.76-.98 1.2-2.17 1.24-3.43zm-3.26 2.72a3.998 3.998 0 01-5.46-5.84c.78-.73 1.8-1.1 2.87-1.08 1.07.04 2.06.48 2.79 1.26s1.11 1.8 1.08 2.87c-.06 1.07-.5 2.06-1.28 2.79z'
      />
    </g>
  </svg>
)

export default SvgFeature5En
