import styled, { css } from 'styled-components'
import { Media } from '@raysync/common/theme'

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 46px;
  ${Media.phone`
    margin-top: .46rem;
  `}
`

export const CardTitle = styled.h4`
  ${({ theme: { color, spacing } }) => css`
    color: ${color.primary};
    height: 68px;
    display: flex;
    align-items: center;
    padding-left: ${spacing.base};
    line-height: 24px;
    font-weight: 400;
    font-size: 18px;
    ${Media.phone`
      height: 1.1rem;
      font-size: .3rem;
      padding-left: .3rem;
    `}
  `}
`

export const DownloadBox = styled.a`
  ${({ theme: { color, spacing } }) => css`
    display: inline-block;
    text-decoration: none;
    color: ${color.secondary};
    padding: 0 0 16px ${spacing.base};
    cursor: pointer;
    line-height: 16px;
    svg {
      width: 14px;
      height: 14px;
      margin-right: 8px;
      margin-bottom: 2px;
    }
    ${Media.phone`
      display: block;
      font-size: .3rem;
      padding-left: .3rem;
    `}
  `}
`
export const CardContainer = styled.div`
  width: 384px;
  box-shadow: 0px 2px 6px 0px rgba(37, 42, 58, 0.14);
  margin-bottom: 40px;
  background: #fff;
  border-radius: 10px;
  :hover {
    box-shadow: 0px 10px 18px 2px rgba(153, 153, 153, 0.3);
  }
  img {
    width: 100%;
    height: 180px;
  }
  ${p =>
    p.size === 'large' &&
    css`
      width: 448px;

      img {
        height: 210px;
      }
      h4 {
        height: unset;
        padding: 30px 30px 28px;
        font-size: 20px;
      }
      a {
        padding: 0 30px 30px;
      }
    `}
  ${Media.phone`
    width: 100%;
    img{
      height: 2.8rem;
    }
  `}
`
