import styled, { css } from 'styled-components'
import { LargeWrapper, Flex } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'

export const FileWrapper = styled(LargeWrapper)`
  width: 1400px;
  max-width: 1400px;
`
export const FeatureSection = styled(FileWrapper)`
  padding: 68px 0 70px 0;
`
export const ButtonContainer = styled(Flex)`
  ${({ theme: { spacing } }) => css`
    padding-top: ${spacing.base};
    justify-content: center;
    button:last-child {
      margin-left: ${spacing.base};
    }
  `}
`

export const ExampleSection = styled.div`
  ${({ theme: { color } }) => css`
    padding: 68px 0;
    background-color: ${color.panel};
    ${Media.phone`
      padding-top:  .72rem;
      padding-bottom: .87rem;
    `}
  `}
`
export const ExampleContainer = styled(Flex)`
  ${({ theme: { spacing } }) => css`
    justify-content: space-between;
    padding: 40px 32px 34px 40px;
    background: white;
    box-shadow: 0px 0px 14px 0px #e2e7ec;
    border-radius: 20px;
    margin-bottom: ${spacing.base};
    > img {
      display: block;
      height: 260px;
      padding-left: 10px;
    }

    ${Media.phone`
      padding: .4rem .32rem .4rem .36rem;
      flex-wrap: wrap;
      div:first-child {
      margin: 0 auto;
      }
      > img {
        width: 5.4rem;
        height: initial;
        margin: 0;
      }
    `}
  `}
`
export const ExampleContent = styled.div`
  ${({ theme: { color, typography, Hack } }) => css`
    > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > img {
        height: 36px;
        margin-right: 30px;
        margin-bottom: 4px;
      }
      h4 {
        font-size: 18px;
        font-weight: 700;
      }
    }
    p {
      font-size: ${typography.textThin};
      color: ${color.text};
      line-height: 24px;
      margin-top: 12px;
    }

    ${Hack.desktopEn`
      h4 {
        font-size: ${typography.h3};
        line-height: 1.2;
      }
    `}

    ${Media.phone`
      > img {
        height: .36rem;
        margin: .38rem 0 .32rem 0;
      }
    `}
  `}
`

export const ArchSection = styled(FileWrapper)`
  padding: 78px 50px 100px;
  ${Media.phone`
    padding-top:  .6rem;
    padding-bottom: 1rem;
  `}
`

export const ArchContent = styled.div`
  padding-top: 97px;
  svg {
    display: block;
    width: 100%;
  }
  ${Media.phone`
    padding-top:  .7rem;
    svg {
      height: auto;
    }
  `}
`

export const MainFeatureSection = styled.div`
  ${({ theme: { color } }) => css`
    padding: 70px 0 20px 0;
    ${Media.phone`
      background: white;
      padding-top: 0rem;
      padding-bottom: .6rem;
    `}
  `}
`
export const MainFeatureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    width: 720px;
    height: 450px;
    box-shadow: 0px 10px 18px 2px rgba(102, 102, 102, 0.3);
  }
`
export const ImgWrap = styled.div`
  img {
    display: inline-block;
    width: 660px;
    margin-right: 64px;
  }
`
export const MainFuncWrap = styled.div``

export const MainItem = styled.div`
  ${({ theme: { color, typography, Hack } }) => css`
    padding: 30px 40px 20px 30px;
    width: 620px;
    min-height: 120px;
    background: ${p => (p.active ? p.theme.color.panel : '#fff')};
    border-radius: 10px;
    position: relative;
    h2 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    p {
      min-height: 40px;
      font-size: 14px;
      color: ${color.text};
      line-height: 24px;
    }
  `}
`
export const MobileMainItem = styled.div`
  ${({ theme: { color } }) => css`
    width: 6.5rem;
    padding: 0.4rem 0.3rem 0.2rem;
    min-height: 7rem;
    margin: 0.2rem auto 0.4rem;
    background: white;
    box-shadow: 0px 0px 6px 0px rgba(226, 231, 236, 1);
    border-radius: 10px;
    img {
      display: block;
      margin: 0 auto 0.5rem;
      width: 100%;
    }
    h2 {
      font-size: 0.36rem;
    }
    p {
      font-size: 0.24rem;
      line-height: 0.36rem;
      margin-top: 0.2rem;
      color: ${color.text};
    }
  `}
`

export const FeatureContainer = styled.div`
  ${({ theme: { color, typography, spacing, Hack } }) => css`
    width: 100%;
    background: white;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    div {
      padding-top: 44px;
      max-width: 530px;
      h3 {
        line-height: 28px;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 700;
      }
      span,
      li {
        font-size: 14px;
        color: ${color.text};
        line-height: 24px;
      }
    }
    svg {
      display: block;
      width: 700px;
      height: 100%;
    }
    ${Hack.desktopEn`
      div h3 {
        line-height: 1.2;
        margin-bottom: 16px;
      }
    `}

    ${Media.phone`
      padding: 0;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      svg {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin-bottom: .4rem;
      }

      div {
        h3 {
          font-size: .3rem;
        }
        ul {
          font-size: .24rem;
        }
      }
    `}
  `}
`
