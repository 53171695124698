import React from 'react'

const SvgFeature1En = props => (
  <svg id='feature1_en_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 684 220' xmlSpace='preserve' {...props}>
    <style>
      {
        '.feature1_en_svg__st0{fill:none;stroke:#8796aa;stroke-miterlimit:10;stroke-dasharray:4,4}.feature1_en_svg__st1{fill:#fd5c1f}.feature1_en_svg__st2{fill:#252a3a}.feature1_en_svg__st3{fill:#00b5b0}.feature1_en_svg__st4{fill:#8796aa}.feature1_en_svg__st5{font-family:&apos;MyriadPro-Regular&apos;}.feature1_en_svg__st6{font-size:11px}'
      }
    </style>
    <path
      className='feature1_en_svg__st0'
      d='M243.37 30.87c20.79.09 38.82.22 53.35.35v180h-294v-180c66.8-.19 139.31-.39 161-.43'
    />
    <path
      className='feature1_en_svg__st1'
      d='M113.76 56.46H99.52v24h19.86V61.62l-5.62-5.16zm-12.59 22.35V58.12h10.76v4.97h5.79v15.72h-16.55z'
    />
    <path
      className='feature1_en_svg__st1'
      d='M102.83 66.39h7.73v1.66h-7.73zM102.83 70.53h12.41v1.66h-12.41zM102.83 74.67h12.41v1.66h-12.41zM109.37 113.72l-3.02-2.5h-6.9v20h20v-17.5h-10.08zm-3.57-.84l2.64 2.5h9.34v1.67h-16.67v-4.17h4.69zm-4.68 16.67v-10.83h16.67v10.83h-16.67zM378.33 108.21c-2.91 0-5.81.56-8.37 1.19l1.89 1.36c-2.55.48-5.16.34-6.59.02v5.57c1.94.51 8.33.14 11.13-1.01l-.88-1.06c1.03-.1 1.73-.12 2.81-.12 2.98 0 5.81.41 8.37 1.16v-5.92c-2.41-.6-5.45-1.19-8.36-1.19z'
    />
    <path
      className='feature1_en_svg__st2'
      d='M387.21 129.82c-1.95.2-3.32.52-4.7.92v1.64c-2.16.49-5.65.97-8.46.79-2.9-.18-6.65-1.23-8.27-1.85v-5.89c2.29 1.22 6.63 2.04 10.21 2.04 1.99 0 4.44-.26 5.88-.56l-.28-1.47c1.83-.63 2.97-1.16 5.64-1.42v5.8h-.02zM383.55 116.44c-2.03 0-5 .47-5.84.7l.97 1.56c-2.19.55-4.37.54-5.97.54-3.76 0-6.95-.79-10.27-2.06v5.9c2.55.87 5.82 1.54 8.93 1.54s6.51-.42 9.42-1.43l-.27-.72c1.01-.13 1.88-.19 2.99-.19 2.09 0 3.84.07 6.02.59v-5.92c-1.21-.24-3.95-.51-5.98-.51z'
    />
    <path
      className='feature1_en_svg__st3'
      d='M254.72 120.55v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.02-12.67-2.94z'
    />
    <path
      className='feature1_en_svg__st3'
      d='M254.72 111.83V117c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.02-12.67-2.94zM254.72 128.99v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.01-12.67-2.94z'
    />
    <path
      className='feature1_en_svg__st3'
      d='M267.39 112.28c7.25 0 12.67-2.12 12.67-4.01s-5.42-4.01-12.67-4.01-12.67 2.12-12.67 4.01 5.42 4.01 12.67 4.01z'
    />
    <path className='feature1_en_svg__st4' d='M297.22 120.22h30v2h-30z' />
    <path className='feature1_en_svg__st4' d='M326.64 115.22l8 6-8 6M411.22 120.22h30v2h-30z' />
    <path
      className='feature1_en_svg__st4'
      d='M440.64 115.22l8 6-8 6M178.53 11.28h53.33v34.17h2.5V11.77c0-1.65-1.34-2.99-2.99-2.99h-52.35c-1.65 0-2.99 1.34-2.99 2.99v33.68h2.5V11.28zM169.37 45.45c0 1.84 1.49 3.33 3.33 3.33h65c1.84 0 3.33-1.49 3.33-3.33h-71.66z'
    />
    <text
      transform='translate(11.298 74.06)'
      className='feature1_en_svg__st4 feature1_en_svg__st5 feature1_en_svg__st6'
    >
      {'massive small files'}
    </text>
    <text
      transform='translate(244.778 22.06)'
      className='feature1_en_svg__st4 feature1_en_svg__st5 feature1_en_svg__st6'
    >
      {'Sending Host'}
    </text>
    <text
      transform='translate(11.298 126.209)'
      className='feature1_en_svg__st4 feature1_en_svg__st5 feature1_en_svg__st6'
    >
      {'GB/TB large files'}
    </text>
    <text
      transform='translate(11.778 174.839)'
      className='feature1_en_svg__st4 feature1_en_svg__st5 feature1_en_svg__st6'
    >
      {'Multiple-format'}
    </text>
    <text
      transform='translate(11.778 186.839)'
      className='feature1_en_svg__st4 feature1_en_svg__st5 feature1_en_svg__st6'
    >
      {'files'}
    </text>
    <circle className='feature1_en_svg__st1' cx={101.45} cy={171.28} r={2} />
    <circle className='feature1_en_svg__st1' cx={109.29} cy={171.28} r={2} />
    <circle className='feature1_en_svg__st1' cx={117.45} cy={171.28} r={2} />
    <text
      transform='translate(163.704 192.32)'
      className='feature1_en_svg__st4 feature1_en_svg__st5 feature1_en_svg__st6'
    >
      {'Sending Files'}
    </text>
    <text
      transform='translate(326.704 165.32)'
      className='feature1_en_svg__st4 feature1_en_svg__st5 feature1_en_svg__st6'
    >
      {'Raysync Transfer Engine'}
    </text>
    <text
      transform='translate(178.89 112.209)'
      className='feature1_en_svg__st4 feature1_en_svg__st5 feature1_en_svg__st6'
    >
      {'Compression'}
    </text>
    <text
      transform='translate(520.89 113.209)'
      className='feature1_en_svg__st4 feature1_en_svg__st5 feature1_en_svg__st6'
    >
      {'Decompression'}
    </text>
    <path
      className='feature1_en_svg__st0'
      d='M608.72 30.78c15.91-.09 45.14-.09 74 .43v180h-220v-180c24.01.29 49.86.33 64.14.3'
    />
    <path
      className='feature1_en_svg__st1'
      d='M656.76 56.46h-14.25v24h19.86V61.62l-5.61-5.16zm-12.59 22.35V58.12h10.76v4.97h5.79v15.72h-16.55z'
    />
    <path
      className='feature1_en_svg__st1'
      d='M645.83 66.39h7.73v1.66h-7.73zM645.83 70.53h12.41v1.66h-12.41zM645.83 74.67h12.41v1.66h-12.41zM652.37 113.72l-3.02-2.5h-6.9v20h20v-17.5h-10.08zm-3.57-.84l2.64 2.5h9.34v1.67h-16.67v-4.17h4.69zm-4.68 16.67v-10.83h16.67v10.83h-16.67z'
    />
    <g>
      <path
        className='feature1_en_svg__st3'
        d='M480.72 120.55v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.02-12.67-2.94z'
      />
      <path
        className='feature1_en_svg__st3'
        d='M480.72 111.83V117c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.02-12.67-2.94zM480.72 128.99v5.17c0 1.89 5.42 4.01 12.67 4.01s12.67-2.12 12.67-4.01v-5.17c-2.48 1.92-7.69 2.94-12.67 2.94s-10.19-1.01-12.67-2.94z'
      />
      <path
        className='feature1_en_svg__st3'
        d='M493.39 112.28c7.25 0 12.67-2.12 12.67-4.01s-5.42-4.01-12.67-4.01-12.67 2.12-12.67 4.01 5.42 4.01 12.67 4.01z'
      />
    </g>
    <g>
      <path className='feature1_en_svg__st4' d='M630.16 172.78l-5.94-4.5v4h-28v1h28v4z' />
    </g>
    <g>
      <path className='feature1_en_svg__st4' d='M630.16 121.78l-5.94-4.5v4h-28v1h28v4z' />
    </g>
    <g>
      <path className='feature1_en_svg__st4' d='M517.29 121.78l5.93-4.5v4h72v1h-72v4z' />
    </g>
    <g>
      <path className='feature1_en_svg__st4' d='M630.16 69.78l-5.94-4.5v4h-28v1h28v4z' />
    </g>
    <g>
      <path className='feature1_en_svg__st4' d='M595.22 173.28h1v-104h-1' />
    </g>
    <g>
      <path className='feature1_en_svg__st4' d='M133.29 171.78l3.93-2.5v2h28v1h-28v2z' />
    </g>
    <g>
      <path className='feature1_en_svg__st4' d='M133.29 120.78l3.93-2.5v2h28v1h-28v2z' />
    </g>
    <g>
      <path className='feature1_en_svg__st4' d='M242.16 120.78l-3.94-2.5v2h-72v1h72v2z' />
    </g>
    <g>
      <path className='feature1_en_svg__st4' d='M133.29 68.78l3.93-2.5v2h28v1h-28v2z' />
    </g>
    <g>
      <path className='feature1_en_svg__st4' d='M166.22 172.28h-1v-104h1' />
    </g>
    <g>
      <text
        transform='translate(461.778 22.06)'
        className='feature1_en_svg__st4 feature1_en_svg__st5 feature1_en_svg__st6'
      >
        {'Receiving Host'}
      </text>
    </g>
    <g>
      <circle className='feature1_en_svg__st1' cx={644.45} cy={172.28} r={2} />
      <circle className='feature1_en_svg__st1' cx={652.29} cy={172.28} r={2} />
      <circle className='feature1_en_svg__st1' cx={660.45} cy={172.28} r={2} />
    </g>
    <g>
      <text
        transform='translate(503.704 192.32)'
        className='feature1_en_svg__st4 feature1_en_svg__st5 feature1_en_svg__st6'
      >
        {'Receiving Files'}
      </text>
    </g>
    <path
      className='feature1_en_svg__st3'
      d='M212.19 24.42c-.52-2.86-3.01-5.03-6.02-5.03-2.37 0-4.53 1.37-5.54 3.52-2.73-.1-5.01 2.04-5.11 4.77s2.04 5.01 4.77 5.11h.89l.72-2.59-1.75-1.61 7.34-5.07-4.71 15.88 7.55-4.92-1.88-1.7 2.87.01h.16a4.224 4.224 0 004.06-4.38 4.203 4.203 0 00-3.35-3.99z'
    />
    <g>
      <path
        className='feature1_en_svg__st4'
        d='M541.54 13.28h53.33v34.17h2.5V13.77c0-1.65-1.34-2.99-2.99-2.99h-52.35c-1.65 0-2.99 1.34-2.99 2.99v33.68h2.5V13.28zM532.38 47.45c0 1.84 1.49 3.33 3.33 3.33h65c1.84 0 3.33-1.49 3.33-3.33h-71.66z'
      />
      <path
        className='feature1_en_svg__st3'
        d='M576.21 27.78c-.34 0-.68.03-1 .08v-.08c0-3.87-3.13-7-7-7-3.17 0-5.85 2.11-6.71 5.01-.1 0-.19-.01-.29-.01-3.87 0-7 3.13-7 7s3.13 7 7 7h6.12v-6.73l-3.62 3.73-1.5-1.6 6-6.4 6 6.4-1.5 1.6-3.62-3.73v6.73h6.12v-.08c.33.05.66.08 1 .08 3.31 0 6-2.69 6-6s-2.69-6-6-6z'
      />
    </g>
    <g>
      <path
        className='feature1_en_svg__st4'
        d='M377.89 97.89c11.09.96 19.95 9.82 20.91 20.91h2.01c-.97-12.19-10.72-21.94-22.91-22.91v2h-.01zM352.98 118.8c.96-11.09 9.82-19.95 20.91-20.91v-2.01c-12.19.97-21.94 10.72-22.91 22.91h2v.01zM373.89 143.71c-11.09-.96-19.95-9.82-20.91-20.91h-2.01c.97 12.19 10.72 21.94 22.91 22.91v-2h.01zM398.8 122.8c-.96 11.09-9.82 19.95-20.91 20.91v2.01c12.19-.97 21.94-10.72 22.91-22.91l-2-.01z'
      />
    </g>
  </svg>
)

export default SvgFeature1En
