import React from 'react'

const SvgFeature5 = props => (
  <svg viewBox='0 0 671.45 201.48' {...props}>
    <defs>
      <style>
        {
          '.feature5_svg__cls-1{fill:#fd5c1f}.feature5_svg__cls-2{font-size:14px;font-family:SourceHanSansCN-Normal-GBpc-EUC-H,Source Han Sans CN;letter-spacing:.02em}.feature5_svg__cls-2,.feature5_svg__cls-3{fill:#8796aa}.feature5_svg__cls-5{fill:#252a3a}'
        }
      </style>
    </defs>
    <g id='feature5_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='feature5_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='feature5_svg__cls-1'
          d='M535.65 35.27v5.16c0 1.9 5.41 4 12.66 4s12.67-2.11 12.67-4v-5.16c-2.48 1.92-7.68 2.94-12.67 2.94s-10.18-1.02-12.66-2.94z'
        />
        <path
          className='feature5_svg__cls-1'
          d='M535.65 26.54v5.17c0 1.89 5.41 4 12.66 4s12.69-2.11 12.69-4v-5.17c-2.48 1.93-7.68 2.94-12.67 2.94s-10.2-1.01-12.68-2.94zM535.65 43.71v5.17c0 1.89 5.41 4 12.66 4s12.67-2.12 12.67-4v-5.17c-2.48 1.92-7.68 2.94-12.67 2.94s-10.18-1.02-12.66-2.94z'
        />
        <path
          className='feature5_svg__cls-1'
          d='M548.31 27c7.25 0 12.67-2.12 12.67-4s-5.42-4-12.67-4-12.66 2.12-12.66 4 5.41 4 12.66 4z'
        />
        <text className='feature5_svg__cls-2' transform='translate(0 23.97)'>
          {'\u5B89\u5168\u53CA\u5BA1\u8BA1\u7BA1\u7406'}
        </text>
        <text className='feature5_svg__cls-2' transform='translate(43.05 78.13)'>
          {'\u7528\u6237\u7BA1\u7406'}
        </text>
        <text className='feature5_svg__cls-2' transform='translate(14.35 132.3)'>
          {'\u5B89\u5168\u8BBE\u7F6E\u7BA1\u7406'}
        </text>
        <text className='feature5_svg__cls-2' transform='translate(43.05 186.47)'>
          {'\u5916\u94FE\u7BA1\u7406'}
        </text>
        <text className='feature5_svg__cls-2' transform='translate(571 43.97)'>
          {'\u6570\u636E\u5B58\u50A8\u7BA1\u7406'}
        </text>
        <text className='feature5_svg__cls-2' transform='translate(571 100.97)'>
          {'\u5BA2\u6237\u7AEF\u4F20\u8F93\u7BA1\u7406'}
        </text>
        <text className='feature5_svg__cls-2' transform='translate(571 170.97)'>
          {'\u4E2A\u6027\u5316\u5B9A\u5236'}
        </text>
        <path className='feature5_svg__cls-3' d='M445.97 39h74v-2h-74' />
        <path className='feature5_svg__cls-3' d='M519.54 43.43l7.97-6.04-8.03-5.96M233.97 19h-74v2h74' />
        <path className='feature5_svg__cls-3' d='M160.81 14.43l-7.97 6.04 8.03 5.96M233.97 73h-74v2h74' />
        <path className='feature5_svg__cls-3' d='M160.81 68.01l-7.97 6.04 8.03 5.96M233.97 126h-74v2h74' />
        <path className='feature5_svg__cls-3' d='M160.81 121.6l-7.97 6.04 8.03 5.96M233.97 180h-74v2h74' />
        <path className='feature5_svg__cls-3' d='M160.81 175.18l-7.97 6.04 8.03 5.96M445.97 98h74v-2h-74' />
        <path className='feature5_svg__cls-3' d='M519.54 103.18l7.97-6.04-8.03-5.96M445.97 164h74v-2h-74' />
        <path className='feature5_svg__cls-3' d='M519.54 169.18l7.97-6.04-8.03-5.96' />
        <path
          className='feature5_svg__cls-1'
          d='M140.51 62.83l-11-6.18a4.83 4.83 0 00-4.38 0l-11 6.18a4.62 4.62 0 00-2.21 3.75v13.07a4.64 4.64 0 002.21 3.76l11 6.18a4.77 4.77 0 004.37 0l11-6.18a4.63 4.63 0 002.2-3.76V66.58a4.61 4.61 0 00-2.19-3.75zm.2 16.82a2.62 2.62 0 01-1.18 2l-11 6.19a2.9 2.9 0 01-2.41 0l-11-6.18a2.66 2.66 0 01-1.19-2V66.58a2.66 2.66 0 011.19-2l11-6.18a2.57 2.57 0 011.21-.27 2.52 2.52 0 011.21.27l11 6.18a2.65 2.65 0 011.18 2z'
        />
        <path
          className='feature5_svg__cls-1'
          d='M127.37 67a.6.6 0 00.56 0l2.07-1.19a.63.63 0 00.31-.54v-2.42a.63.63 0 00-.31-.54l-2-1.21a.64.64 0 00-.6 0l-2.08 1.22a.6.6 0 00-.3.52v2.43a.63.63 0 00.31.54zM127 69v5a.78.78 0 00.67.55.81.81 0 00.67-.55v-5a.8.8 0 00-.67-.54.79.79 0 00-.67.54zM136.22 74a.54.54 0 00.27-.07.57.57 0 00.2-.75 15.3 15.3 0 00-6.1-5.72.52.52 0 00-.73.21.54.54 0 00.21.74 14.68 14.68 0 015.69 5.31.53.53 0 00.46.28zM124.71 67.45a15.27 15.27 0 00-6.1 5.73.55.55 0 00.2.75.48.48 0 00.26.07.53.53 0 00.47-.28 14.58 14.58 0 015.69-5.31.55.55 0 00.21-.74.54.54 0 00-.73-.22zM135.02 75.72h4.02v4.02h-4.02zM125.64 75.72h4.02v4.02h-4.02zM116.26 75.72h4.02v4.02h-4.02zM118.75 8.56h9.9a.47.47 0 00.49-.5.46.46 0 00-.39-.5h-9.9a.46.46 0 00-.49.5.45.45 0 00.39.5z'
        />
        <path
          className='feature5_svg__cls-1'
          d='M132.06.56h-15.5c-2 0-3.25 1.69-3.25 3.75V30.8c0 2.07 1.3 3.76 3.25 3.76h20.95a3.88 3.88 0 003.8-3.76v-21zm.25 2.68l6.95 7.32h-5.09c-1.21 0-1.86-1-1.86-2.35zm7 27.4a2.1 2.1 0 01-2.06 1.92h-20.61c-.88 0-1.33-.84-1.33-1.87V4.42c0-1 .53-1.86 1.33-1.86h13.67v6.65a3.1 3.1 0 002.95 3.35h6.05z'
        />
        <path
          className='feature5_svg__cls-1'
          d='M118.14 14.06a.47.47 0 00.49.5h17a.47.47 0 00.5-.5.54.54 0 00-.5-.5h-17a.47.47 0 00-.49.5zM127 26.14a5 5 0 10-.71.71l1.8 1.8a.5.5 0 00.71 0 .48.48 0 000-.7zm-1-.32a4 4 0 110-5.65 4 4 0 010 5.65zM128.58 175.34l-1.11 1.11 3.32 3.31-3.32 3.32 1.11 1.11 4.42-4.43-4.42-4.42zM120.49 175.34l-4.42 4.42 4.42 4.43 1.11-1.11-3.32-3.32 3.32-3.31-1.11-1.11z'
        />
        <path
          className='feature5_svg__cls-1'
          transform='rotate(-69.44 124.527 179.829)'
          d='M119.85 179.05h9.38v1.56h-9.38z'
        />
        <path className='feature5_svg__cls-1' d='M112.31 167.48v34h11.96v-1.7h-10.24v-30.6h26.56v17h1.72v-18.7h-30z' />
        <path
          className='feature5_svg__cls-1'
          d='M141.32 194.3a6 6 0 00-10.1-4.58 6 6 0 004.1 10.38 5.93 5.93 0 003.3-1l2.23 2.39 1.46-1.37-2.22-2.38a6 6 0 001.23-3.44zm-3.27 2.7a4 4 0 01-5.47-5.84 4.08 4.08 0 012.87-1.08 4 4 0 012.6 6.92z'
        />
        <text className='feature5_svg__cls-2' transform='translate(295.04 156.46)'>
          {'\u956D\u901F\u7BA1\u7406\u540E\u53F0'}
        </text>
        <path
          className='feature5_svg__cls-3'
          d='M379.55 59.57h-82.92a6.92 6.92 0 00-6.91 6.91v46.68a6.92 6.92 0 006.91 6.91h36.8L332 125.6h-9.49v2h31.24v-2h-9.49l-1.47-5.53h36.8a6.92 6.92 0 006.91-6.91V66.48a6.92 6.92 0 00-6.95-6.91zm4.93 53.59a4.94 4.94 0 01-4.93 4.94h-82.92a5 5 0 01-4.94-4.94V66.48a4.94 4.94 0 014.94-4.93h82.92a4.93 4.93 0 014.93 4.93z'
        />
        <path
          d='M360.76.5h85.33v200H234V.5h126.76'
          fill='none'
          stroke='#8796aa'
          strokeMiterlimit={10}
          strokeDasharray='4 4'
        />
        <path
          className='feature5_svg__cls-1'
          d='M341.15 77a42.89 42.89 0 00-10 1.42l2.27 1.64a21.85 21.85 0 01-7.91 0v6.69c2.33.62 10 .16 13.36-1.21l-1.06-1.27a28.91 28.91 0 013.38-.15 35.72 35.72 0 0110 1.39v-7.05A43.72 43.72 0 00341.15 77z'
        />
        <path
          className='feature5_svg__cls-5'
          d='M351.8 103a29.9 29.9 0 00-5.64 1.11v2A40.41 40.41 0 01336 107a41 41 0 01-9.92-2.22v-7.07c2.75 1.46 8 2.45 12.25 2.45a39.63 39.63 0 007.05-.67l-.38-1.76a24.39 24.39 0 016.8-1.73zM347.41 86.92a36.36 36.36 0 00-7 .84l1.17 1.87a29.05 29.05 0 01-7.17.65 33.49 33.49 0 01-12.32-2.46v7.08a34.75 34.75 0 0010.71 1.85A35.56 35.56 0 00344.09 95l-.32-.87a27.27 27.27 0 013.58-.23 29.17 29.17 0 017.23.71v-7.1a42.25 42.25 0 00-7.17-.59z'
        />
        <path
          className='feature5_svg__cls-1'
          d='M544.9 92.1h2v2h-2zM541.9 92.1h2v2h-2zM555.9 96.1h-17.75l2.75 3v-1h17v-5h-2v3zM541.9 107.1h12v2h-12z'
        />
        <path className='feature5_svg__cls-1' d='M532.9 83.1v32h30v-32zm2 30v-28h26v28z' />
        <path
          className='feature5_svg__cls-1'
          d='M539.9 90.1h17.75l-2.75-3v1h-17v5h2v-3zM547.9 92.1h2v2h-2zM550.9 92.1h2v2h-2zM141.87 119.09v-2l-1.75-.48a49.61 49.61 0 01-12.81-6.08l-.57-.36-.56.36c-5 3.19-8.85 5.07-12.54 6.1l-1.75.48v2c-.13 7.34 1.61 13.29 4.9 16.77 3.47 3.68 6.14 5.65 9.55 7l.38.15.4-.16a29 29 0 0010.19-7c3.13-3.36 4.69-9.14 4.56-16.78zm-5.6 15.75a27.73 27.73 0 01-9.55 6.64c-3.12-1.29-5.62-3.16-8.9-6.63-3-3.2-4.63-8.8-4.5-15.73v-1l.7-.2a47.06 47.06 0 0012.73-6.14 49.8 49.8 0 0013 6.12l.7.2v1c.13 7.28-1.32 12.72-4.18 15.74z'
        />
        <path
          className='feature5_svg__cls-1'
          d='M127 117.43a5.72 5.72 0 00-4.38 9.39v8.09l4.43-3.26 4.15 3.41v-8a5.71 5.71 0 00-4.2-9.59zm0 1.43a4.29 4.29 0 11-4.28 4.29 4.29 4.29 0 014.28-4.29zm.1 11l-3 2.24V128a5.63 5.63 0 003 .83 5.73 5.73 0 002.77-.71V132z'
        />
        <path
          className='feature5_svg__cls-1'
          d='M127 126a2.86 2.86 0 10-2.86-2.85A2.86 2.86 0 00127 126zm0-4.28a1.43 1.43 0 11-1.43 1.43 1.43 1.43 0 011.43-1.43zM551.11 183.56a6.52 6.52 0 01-9.21-5.71v-2.75h2v2.63a4.5 4.5 0 109 .12v-8.75h2v8.63a6.21 6.21 0 01-3.79 5.83z'
        />
        <path
          className='feature5_svg__cls-1'
          d='M559.65 173.1h-2.75v-2h2.64a4.56 4.56 0 004.36-3 4.44 4.44 0 00-4.25-6h-8.75v-2h8.63a6.22 6.22 0 015.84 3.79 6.52 6.52 0 01-5.72 9.21zM546.9 173.1h-8.63a6.24 6.24 0 01-5.84-3.79 6.52 6.52 0 015.72-9.21h2.75v2h-2.64a4.5 4.5 0 00-4.45 2.9 4.57 4.57 0 004.34 6.1h8.75z'
        />
        <path
          className='feature5_svg__cls-1'
          d='M543.9 165.1h-2v-8.63a6.23 6.23 0 013.79-5.84 6.52 6.52 0 019.21 5.72v2.75h-2v-2.64a4.69 4.69 0 00-3-4.36c-2-1-6 1-6 4.25z'
        />
      </g>
    </g>
  </svg>
)

export default SvgFeature5
