import React from 'react'

const SvgFeature1 = props => (
  <svg viewBox='0 0 695.33 181.74' {...props}>
    <defs>
      <style>
        {
          '.feature1_svg__cls-1{fill:none;stroke:#8796aa;stroke-miterlimit:10;stroke-dasharray:4 4}.feature1_svg__cls-2{fill:#fd5c1f}.feature1_svg__cls-3{fill:#00b5b0}.feature1_svg__cls-4,.feature1_svg__cls-6{fill:#8796aa}.feature1_svg__cls-5{fill:#252a3a}.feature1_svg__cls-6{font-size:12px;font-family:SourceHanSansCN-Normal-GBpc-EUC-H,Source Han Sans CN;letter-spacing:.02em}'
        }
      </style>
    </defs>
    <g id='feature1_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='feature1_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='feature1_svg__cls-1'
          d='M220.3 21.31c18.38.08 83.85.2 96.7.31v159.57H57V21.62c59.07-.17 64.84-.34 84-.38'
        />
        <path className='feature1_svg__cls-2' d='M211 66.68h-14.2v24h19.86V71.83zM198.45 89V68.33h10.76v5H215V89z' />
        <path
          className='feature1_svg__cls-2'
          d='M200.11 76.61h7.73v1.66h-7.73zM200.11 80.75h12.41v1.66h-12.41zM200.11 84.88h12.41v1.66h-12.41zM206.65 129.93l-3-2.5h-6.9v20h20v-17.5zm-3.57-.83l2.64 2.5h9.34v1.67H198.4v-4.17zm-4.68 16.67v-10.84h16.66v10.84z'
        />
        <path
          className='feature1_svg__cls-3'
          d='M0 105.25v5.17c0 1.89 5.42 4 12.67 4s12.66-2.12 12.66-4v-5.17c-2.47 1.93-7.68 2.94-12.66 2.94S2.48 107.18 0 105.25z'
        />
        <path
          className='feature1_svg__cls-3'
          d='M0 96.53v5.17c0 1.89 5.42 4 12.67 4s12.66-2.12 12.66-4v-5.17c-2.47 1.92-7.68 2.94-12.66 2.94S2.48 98.45 0 96.53zM0 113.7v5.16c0 1.9 5.42 4 12.67 4s12.66-2.12 12.66-4v-5.16c-2.47 1.92-7.68 2.94-12.66 2.94S2.48 115.62 0 113.7z'
        />
        <path
          className='feature1_svg__cls-3'
          d='M12.67 97c7.25 0 12.66-2.12 12.66-4s-5.41-4-12.66-4S0 91.08 0 93s5.42 4 12.67 4z'
        />
        <path className='feature1_svg__cls-4' d='M317.5 104.92h30v2h-30z' />
        <path className='feature1_svg__cls-4' d='M346.92 99.92l8 6-8 6M432.5 104.92h30v2h-30z' />
        <path className='feature1_svg__cls-4' d='M461.92 99.92l8 6-8 6' />
        <path
          className='feature1_svg__cls-2'
          d='M184.3 10.43a23.46 23.46 0 00-5.49.78l1.24.89a12 12 0 01-4.33 0v3.66a17.48 17.48 0 007.31-.67l-.57-.69a15.88 15.88 0 011.84-.08 19.7 19.7 0 015.5.76v-3.87a23.91 23.91 0 00-5.5-.78z'
        />
        <path
          className='feature1_svg__cls-5'
          d='M190.13 24.62a15.5 15.5 0 00-3.08.61v1.07a21.77 21.77 0 01-5.56.52 22.36 22.36 0 01-5.43-1.21v-3.87a16.45 16.45 0 006.7 1.34 22.45 22.45 0 003.86-.36l-.19-1a13.27 13.27 0 013.7-.93zM187.73 15.83a19.41 19.41 0 00-3.84.47l.64 1a15.92 15.92 0 01-3.92.36 18.45 18.45 0 01-6.74-1.35v3.89a19 19 0 005.86 1 19.61 19.61 0 006.19-.94l-.18-.47a13.63 13.63 0 012-.13 15.85 15.85 0 014 .39v-3.87a23.46 23.46 0 00-4.01-.35z'
        />
        <path
          className='feature1_svg__cls-4'
          d='M155 2.5h53.3v34.17h2.5V3a3 3 0 00-3-3h-52.35a3 3 0 00-3 3v33.67H155zM145.8 36.67a3.33 3.33 0 003.33 3.33h65a3.33 3.33 0 003.33-3.33z'
        />
        <text className='feature1_svg__cls-6' transform='translate(226.58 86.28)'>
          {'\u6D77\u91CF\u5C0F\u6587\u4EF6'}
        </text>
        <text
          transform='translate(226.58 104.28)'
          fontSize={12}
          fontFamily='SourceHanSansCN-Normal-GBpc-EUC-H,Source Han Sans CN'
          letterSpacing='.02em'
          fill='#fd5c1f'
        >
          {'\u4E0D\u9650\u6587\u4EF6\u683C\u5F0F'}
        </text>
        <text className='feature1_svg__cls-6' transform='translate(229.06 13.28)'>
          {'\u53D1\u9001\u7AEF'}
        </text>
        <text className='feature1_svg__cls-6' transform='translate(225.58 142.43)'>
          {'GB/TB\u5927\u6587\u4EF6'}
        </text>
        <text className='feature1_svg__cls-6' transform='translate(85.17 96.43)'>
          {'\u667A\u80FD\u538B\u7F29'}
        </text>
        <text className='feature1_svg__cls-6' transform='translate(86.17 124.43)'>
          {'\u5206\u5757\u5E76\u53D1'}
        </text>
        <text className='feature1_svg__cls-6' transform='translate(588.17 97.43)'>
          {'\u89E3\u538B'}
        </text>
        <text className='feature1_svg__cls-6' transform='translate(588.17 125.43)'>
          {'\u5408\u5E76'}
        </text>
        <path
          className='feature1_svg__cls-1'
          d='M600.28 21.28c13-.09 27.11-.08 50.72.38v159.58H471V21.66c19.65.26 50.62.29 62.3.27'
        />
        <path className='feature1_svg__cls-2' d='M512 66.68h-14.2v24h19.86V71.83zM499.45 89V68.33h10.76v5H516V89z' />
        <path
          className='feature1_svg__cls-2'
          d='M501.11 76.61h7.73v1.66h-7.73zM501.11 80.75h12.41v1.66h-12.41zM501.11 84.88h12.41v1.66h-12.41zM507.65 129.93l-3-2.5h-6.9v20h20v-17.5zm-3.57-.83l2.64 2.5h9.34v1.67H499.4v-4.17zm-4.68 16.67v-10.84h16.66v10.84z'
        />
        <path
          className='feature1_svg__cls-3'
          d='M670 105.25v5.17c0 1.89 5.42 4 12.67 4s12.66-2.12 12.66-4v-5.17c-2.47 1.93-7.68 2.94-12.66 2.94s-10.19-1.01-12.67-2.94z'
        />
        <path
          className='feature1_svg__cls-3'
          d='M670 96.53v5.17c0 1.89 5.42 4 12.67 4s12.66-2.12 12.66-4v-5.17c-2.47 1.92-7.68 2.94-12.66 2.94s-10.19-1.02-12.67-2.94zM670 113.7v5.16c0 1.9 5.42 4 12.67 4s12.66-2.12 12.66-4v-5.16c-2.47 1.92-7.68 2.94-12.66 2.94s-10.19-1.02-12.67-2.94z'
        />
        <path
          className='feature1_svg__cls-3'
          d='M682.67 97c7.25 0 12.66-2.12 12.66-4s-5.41-4-12.66-4S670 91.08 670 93s5.42 4 12.67 4z'
        />
        <path
          className='feature1_svg__cls-4'
          d='M33.57 106.75l3.93-2.5v2h117v1h-117v2l-3.93-2.5zM186.44 136.25l-3.94-2.5v2h-28v1h28v2l3.94-2.5zM187.44 77.25l-3.94-2.5v2h-28v1h28v2l3.94-2.5z'
        />
        <path
          className='feature1_svg__cls-4'
          d='M154.5 136.75h1v-60h-1M665.44 106.75l-3.94-2.5v2h-95v1h95v2l3.94-2.5zM534.57 136.25l3.93-2.5v2h28v1h-28v2l-3.93-2.5zM533.57 77.25l3.93-2.5v2h28v1h-28v2l-3.93-2.5z'
        />
        <path className='feature1_svg__cls-4' d='M566.5 136.75h-1v-60h1' />
        <text className='feature1_svg__cls-6' transform='translate(484.06 13.28)'>
          {'\u63A5\u6536\u7AEF'}
        </text>
        <path
          className='feature1_svg__cls-2'
          d='M569.4 12a23.46 23.46 0 00-5.49.78l1.24.9a12 12 0 01-4.33 0v3.66a17.46 17.46 0 007.31-.66l-.57-.7a15.79 15.79 0 011.84-.08 19.7 19.7 0 015.5.76v-3.9a23.91 23.91 0 00-5.5-.76z'
        />
        <path
          className='feature1_svg__cls-5'
          d='M575.23 26.18a16.6 16.6 0 00-3.09.6v1.08a22.15 22.15 0 01-5.55.52 21.86 21.86 0 01-5.43-1.22V23.3a16.62 16.62 0 006.7 1.34 21.42 21.42 0 003.86-.37l-.19-1a13.18 13.18 0 013.7-.93zM572.83 17.39a19.33 19.33 0 00-3.84.46l.64 1a16 16 0 01-3.92.36 18.21 18.21 0 01-6.71-1.33v3.88a19 19 0 005.86 1 19.51 19.51 0 006.18-.94l-.18-.47a15.48 15.48 0 012-.13 15.93 15.93 0 014 .39v-3.87a22.53 22.53 0 00-4.03-.35z'
        />
        <path
          className='feature1_svg__cls-4'
          d='M541.19 2.5h53.33v34.17H597V3a3 3 0 00-3-3h-52.32a3 3 0 00-3 3v33.67h2.5zM532 36.67a3.34 3.34 0 003.36 3.33h65a3.33 3.33 0 003.33-3.33z'
        />
        <path
          className='feature1_svg__cls-3'
          d='M395.08 124.17h11.75V124a11.14 11.14 0 001.92.16 11.53 11.53 0 100-23 11.14 11.14 0 00-1.92.16v-.16a13.45 13.45 0 00-26.34-3.83h-.55a13.45 13.45 0 100 26.89h11.75M404.91 104.38l-2.88 3.08'
        />
      </g>
    </g>
  </svg>
)

export default SvgFeature1
