import React from 'react'
import intl from 'react-intl-universal'
import { FileBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, Button, Wrapper, H2, H4, H3, Link } from '@raysync/common/components'
import { useMedia, useHover } from '@raysync/common/hooks'
import DocumentCard from '../../components/document-cards'
import Layout from '../../components/layout'
import { LOCALES, LocaleService } from '@raysync/common/services'
import {
  MainFeatureSection,
  MainFeatureContainer,
  MainItem,
  MobileMainItem,
  FeatureSection,
  FeatureContainer,
  ButtonContainer,
  ArchSection,
  ArchContent,
  ExampleSection,
  ExampleContent,
  ExampleContainer,
  MainFuncWrap,
  FileWrapper,
} from './product-file.atoms'

import Logo from '../../images/products/file/huawei.png'
import Map from '../../images/products/file/map.png'
import Main1 from '../../images/products/file/main1.png'
import Main2 from '../../images/products/file/main2.png'
import Main3 from '../../images/products/file/main3.png'
import Main4 from '../../images/products/file/main4.png'
import Main5 from '../../images/products/file/main5.png'
import Main6 from '../../images/products/file/main6.png'
import Main7 from '../../images/products/file/main7.png'
import Main8 from '../../images/products/file/main8.png'
import MainEn1 from '../../images/products/file/main1-en.png'
import MainEn2 from '../../images/products/file/main2-en.png'
import MainEn3 from '../../images/products/file/main3-en.png'
import MainEn4 from '../../images/products/file/main4-en.png'
import MainEn5 from '../../images/products/file/main5-en.png'
import MainEn6 from '../../images/products/file/main6-en.png'
import MainEn7 from '../../images/products/file/main7-en.png'
import MainEn8 from '../../images/products/file/main8-en.png'

import Feature1 from '@raysync/common/components/svg/products/file/feature1'
import Feature2 from '@raysync/common/components/svg/products/file/feature2'
import FeatureA3 from '@raysync/common/components/svg/products/file/feature3-1'
import FeatureB3 from '@raysync/common/components/svg/products/file/feature3-2'
import Feature4 from '@raysync/common/components/svg/products/file/feature4'
import Feature5 from '@raysync/common/components/svg/products/file/feature5'
import Feature6 from '@raysync/common/components/svg/products/file/feature6'

import FeatureEn1 from '@raysync/common/components/svg/products/file/feature1-en'
import FeatureEn2 from '@raysync/common/components/svg/products/file/feature2-en'
import FeatureEn3 from '@raysync/common/components/svg/products/file/feature3-en'
import FeatureEn4 from '@raysync/common/components/svg/products/file/feature4-en'
import FeatureEn5 from '@raysync/common/components/svg/products/file/feature5-en'
import FeatureEn6 from '@raysync/common/components/svg/products/file/feature6-en'

import Arch from '@raysync/common/components/svg/products/file/arch'

import ArchEn from '@raysync/common/components/svg/products/file/arch-en'

const currentSvg = {
  [LOCALES.ZH]: {
    arch: <Arch />,
    feature1: <Feature1 />,
    feature2: <Feature2 />,
    feature3: (
      <span>
        <FeatureA3 style={{ height: '300px' }} />
        <br />
        <FeatureB3 style={{ height: '440px' }} />
      </span>
    ),
    feature4: <Feature4 />,
    feature5: <Feature5 style={{ width: '620px' }} />,
    feature6: <Feature6 style={{ width: '640px' }} />,
  },
  [LOCALES.EN]: {
    arch: <ArchEn />,
    feature1: <FeatureEn1 />,
    feature2: <FeatureEn2 />,
    feature3: <FeatureEn3 style={{ width: '730px' }} />,
    feature4: <FeatureEn4 />,
    feature5: <FeatureEn5 style={{ width: '620px' }} />,
    feature6: <FeatureEn6 style={{ width: '640px' }} />,
  },
  [LOCALES.JA]: {
    arch: <ArchEn />,
    feature1: <FeatureEn1 />,
    feature2: <FeatureEn2 />,
    feature3: <FeatureEn3 style={{ width: '730px' }} />,
    feature4: <FeatureEn4 />,
    feature5: <FeatureEn5 style={{ width: '620px' }} />,
    feature6: <FeatureEn6 style={{ width: '640px' }} />,
  },
}[LocaleService.currentLocale]

const videoSource = {
  [LOCALES.ZH]: 'https://raysync.oss-cn-shenzhen.aliyuncs.com/setup/document/Large%20Files%20PK-CN.mp4',
  [LOCALES.EN]: 'https://raysync.oss-cn-shenzhen.aliyuncs.com/setup/document/Large%20Files%20PK-EN.mp4',
  [LOCALES.JA]: 'https://raysync.oss-cn-shenzhen.aliyuncs.com/setup/document/Large%20Files%20PK-EN.mp4',
}[LocaleService.currentLocale]

const MainFuncItem = ({ onTrigger, children, active }) => {
  const ref = React.useRef()
  useHover(ref, onTrigger, true)
  return (
    <MainItem ref={ref} active={active}>
      {children}
    </MainItem>
  )
}
const MainFeature = () => {
  const mainList = [
    {
      title: intl.get('product1.main.item1.title'),
      desc: intl.get('product1.main.item1.desc'),
      img: LocaleService.currentLocale !== LOCALES.ZH ? MainEn1 : Main1,
    },
    {
      title: intl.get('product1.main.item2.title'),
      desc: intl.get('product1.main.item2.desc'),
      img: LocaleService.currentLocale !== LOCALES.ZH ? MainEn2 : Main2,
    },
    {
      title: intl.get('product1.main.item3.title'),
      desc: intl.get('product1.main.item3.desc'),
      img: LocaleService.currentLocale !== LOCALES.ZH ? MainEn3 : Main3,
    },
    {
      title: intl.get('product1.main.item4.title'),
      desc: intl.get('product1.main.item4.desc'),
      img: LocaleService.currentLocale !== LOCALES.ZH ? MainEn4 : Main4,
    },
    {
      title: intl.get('product1.main.item5.title'),
      desc: intl.get('product1.main.item5.desc'),
      img: LocaleService.currentLocale !== LOCALES.ZH ? MainEn5 : Main5,
    },
    {
      title: intl.get('product1.main.item6.title'),
      desc: intl.get('product1.main.item6.desc'),
      img: LocaleService.currentLocale !== LOCALES.ZH ? MainEn6 : Main6,
    },
    {
      title: intl.get('product1.main.item7.title'),
      desc: intl.get('product1.main.item7.desc'),
      img: LocaleService.currentLocale !== LOCALES.ZH ? MainEn7 : Main7,
    },
    {
      title: intl.get('product1.main.item8.title'),
      desc: intl.get('product1.main.item8.desc'),
      img: LocaleService.currentLocale !== LOCALES.ZH ? MainEn8 : Main8,
    },
  ]
  const [userActived, setUserActived] = React.useState(0)
  const [adminActived, setAdminActived] = React.useState(4)
  const isMobile = useMedia()

  if (isMobile) {
    return (
      <FileWrapper>
        <CommonTitle bottom={53}>{intl.get('product1.main')}</CommonTitle>
        <Swiper swiperNavType='touch'>
          {mainList.map((l, i) => (
            <MobileMainItem key={i}>
              <img src={l.img} alt={l.title} />
              <H2>{l.title}</H2>
              <p>{l.desc}</p>
            </MobileMainItem>
          ))}
        </Swiper>
      </FileWrapper>
    )
  }
  return (
    <FileWrapper>
      <CommonTitle bottom={53}>{intl.get('product1.main.user.title')}</CommonTitle>
      <MainFeatureContainer>
        <MainFuncWrap>
          {mainList.slice(0, 4).map((l, i) => (
            <MainFuncItem
              key={i}
              active={userActived === i}
              onTrigger={() => {
                setUserActived(i)
              }}
            >
              <H2>{l.title}</H2>
              <p>{l.desc}</p>
            </MainFuncItem>
          ))}
        </MainFuncWrap>
        <img src={mainList[userActived].img} alt='user-end' />
      </MainFeatureContainer>
      <CommonTitle top={80} bottom={53}>
        {intl.get('product1.main.admin.title')}
      </CommonTitle>
      <MainFeatureContainer style={{ flexDirection: 'row-reverse' }}>
        <MainFuncWrap>
          {mainList.slice(4).map((l, i) => (
            <MainFuncItem
              key={i}
              active={adminActived === i + 4}
              onTrigger={() => {
                setAdminActived(i + 4)
              }}
            >
              <H2>{l.title}</H2>
              <p>{l.desc}</p>
            </MainFuncItem>
          ))}
        </MainFuncWrap>
        <img src={mainList[adminActived].img} alt='admin-end' />
      </MainFeatureContainer>
    </FileWrapper>
  )
}

class ProductFile extends React.PureComponent {
  state = {
    featureList: [
      {
        title: intl.get('product1.feature.item1.title'),
        desc: intl.getHTML('product1.feature.item1.desc'),
        svg: currentSvg.feature1,
      },
      {
        title: intl.get('product1.feature.item2.title'),
        desc: intl.getHTML('product1.feature.item2.desc'),
        svg: currentSvg.feature2,
      },
      {
        title: intl.get('product1.feature.item3.title'),
        desc: intl.getHTML('product1.feature.item3.desc'),
        svg: currentSvg.feature3,
      },
      {
        title: intl.get('product1.feature.item4.title'),
        desc: intl.getHTML('product1.feature.item4.desc'),
        svg: currentSvg.feature4,
      },
      {
        title: intl.get('product1.feature.item5.title'),
        desc: intl.getHTML('product1.feature.item5.desc'),
        svg: currentSvg.feature5,
      },
      {
        title: intl.get('product1.feature.item6.title'),
        desc: intl.getHTML('product1.feature.item6.desc'),
        svg: currentSvg.feature6,
      },
    ],
    userList: [
      {
        title: intl.get('product1.main.item1.title'),
        desc: intl.getHTML('product1.main.item1.desc'),
        img: Main1,
      },
      {
        title: intl.get('product1.main.item2.title'),
        desc: intl.getHTML('product1.main.item2.desc'),
        img: Main2,
      },
      {
        title: intl.get('product1.main.item3.title'),
        desc: intl.getHTML('product1.main.item3.desc'),
        img: Main3,
      },
      {
        title: intl.get('product1.main.item4.title'),
        desc: intl.getHTML('product1.main.item4.desc'),
        img: Main4,
      },
      {
        title: intl.get('product1.main.item5.title'),
        desc: intl.getHTML('product1.main.item5.desc'),
        img: Main5,
      },
      {
        title: intl.get('product1.main.item6.title'),
        desc: intl.getHTML('product1.main.item6.desc'),
        img: Main6,
      },
      {
        title: intl.get('product1.main.item7.title'),
        desc: intl.getHTML('product1.main.item7.desc'),
        img: Main7,
      },
      {
        title: intl.get('product1.main.item8.title'),
        desc: intl.getHTML('product1.main.item8.desc'),
        img: Main8,
      },
    ],
    activedMainIndex: -1,
  }
  handleTabClick(activedArchTab) {
    this.setState({ activedArchTab })
  }
  handleTrigger(index) {
    this.setState({ activedMainImg: this.state.mainList[index].img })
  }
  render() {
    const { location } = this.props
    const { featureList } = this.state
    return (
      <Layout pageType='product1' location={location} showBanner>
        <FileBanner videoSrc={videoSource} right={6} />
        <FeatureSection>
          <CommonTitle bottom={44}>{intl.get('product1.feature')}</CommonTitle>
          {featureList.map((l, i) => (
            <FeatureContainer key={i}>
              <div>
                <H3>{l.title}</H3>
                <ul>{l.desc}</ul>
              </div>
              {l.svg}
            </FeatureContainer>
          ))}
        </FeatureSection>

        <MainFeatureSection>
          <MainFeature></MainFeature>
        </MainFeatureSection>

        <ArchSection>
          <CommonTitle>{intl.get('product1.deploy.title')}</CommonTitle>
          <ArchContent>{currentSvg.arch}</ArchContent>
        </ArchSection>
        <ExampleSection>
          <Wrapper>
            <CommonTitle bottom={45}>{intl.get('product2.example.title')}</CommonTitle>
            <ExampleContainer>
              <ExampleContent>
                <div>
                  <img src={Logo} alt='Renderbus Logo' />
                  <H4>{intl.get('product1.example.desc1')}</H4>
                </div>
                <p>{intl.get('product1.example.desc2')}</p>
                <p>{intl.get('product1.example.desc3')}</p>
              </ExampleContent>
              <img src={Map} alt='client pic' />
            </ExampleContainer>
            <ButtonContainer>
              <Link to={'/example'}>
                <Button variant='outlined' style={{ margin: '0 auto' }}>
                  {intl.get('product1.example.button')}
                </Button>
              </Link>
            </ButtonContainer>
          </Wrapper>
        </ExampleSection>
        <Wrapper style={{ paddingTop: '68px', paddingBottom: '68px' }}>
          <CommonTitle>{intl.get('app.document.title')}</CommonTitle>
          <DocumentCard type='product1' />
        </Wrapper>
        {LocaleService.currentLocale === LOCALES.ZH && (
          <Swiper>
            <InviteBanner type='product1' />
          </Swiper>
        )}
      </Layout>
    )
  }
}

export default ProductFile
